// FIXME: There may be an argument to move this into the web-config-module package. It's a little awkward because this only works
// in the client as its currently written.

/**
 * currentEnv returns the current environment's parsed URL as a key/value
 * object. The return is the output of `new URL(window.location.href)`
 * with the exception of:
 *
 * rootHostname: root domain
 * productHostname: domain without the first subdomain.
 *
 * @returns Record<string, string>
 */
const currentEnv = (): Record<string, string> => {
  const currentURL = new URL(window.location.href);

  const domainParts = currentURL.hostname.split('.');
  const rootHostname = domainParts.slice(domainParts.length - 2).join('.');
  const productHostname = domainParts.length > 2 ? domainParts.slice(1).join('.') : domainParts.join('.');

  return {
    hash: currentURL.hash,
    hostname: currentURL.hostname,
    productHostname,
    rootHostname,
    host: currentURL.host,
    pathname: currentURL.pathname,
    port: currentURL.port,
    protocol: currentURL.protocol,
    search: currentURL.search,
  };
};

/**
 * resolve applies template variables to a given string value, usually a
 * configuration value. These variables can be used or ignored as part of the
 * string value provided. If an invalid variable is referenced, it will default to
 * an empty string. Values outside of interpolated variables are not mutated.
 *
 * @returns string
 * @see currentEnv
 * @example // For a hosted env of account.etus.example.com use a config url of
 * // https://backend.etus.example.com/
 * // In your config:
 * REACT_APP_BACKEND_URL=http://account.\${productHostname}:3002
 * // During app initialization:
 * const backendUrl = resolve(process.env.REACT_APP_BACKEND_URL) // returns https://account.etus.example.com:3002
 */
const resolveUrlConfigTemplate = (config: string | undefined): string => {
  if (config === undefined) {
    return '';
  }

  const ctx = currentEnv();
  const resolved = config.replace(/\$\{([^}]+)\}/g, (_substr, key) => {
    return ctx[key] || '';
  });

  return resolved;
};

export { resolveUrlConfigTemplate };
