import { createLogger, Environments } from '@eftours/web-logger-typescript';
import { Metric } from 'web-vitals';
import { reportWebVitals, WebVitals } from '../../reportWebVitals';
import { resolveUrlConfigTemplate } from '../../utils/config';
import { getViewportSize } from './window';

const DEFAULT_CLIENT_LOG_TYPE = 'ClientEvent' as const;
const SERVICE_NAME = 'tours-account-homepage' as const;

/**
 * `navigator.userAgentData isn't a fully supported property, but we conditionally use it in the logger below. This
 * would ideally be a type shipped directly from typescript, as a part of `Navigator`. We're just extending it here to be
 * explicit about expected types
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
 */
declare global {
  type NavigatorUAData = {
    brands: string[];
    mobile: boolean;
    platform: string;
  };
  interface Navigator {
    userAgentData?: NavigatorUAData;
  }
}

/**
 * logger is our application-level logger instance. The following env vars need to be
 * set.
 *
 * REACT_APP_LOGGER_SERVICE
 * REACT_APP_LOGGER_ENVIRONMENT
 * REACT_APP_LOGGER_ROLLBAR_ACCESS_TOKEN (if using Rollbar)
 * REACT_APP_LOGGER_BEACON_ENDPOINT
 */
const logger = createLogger({
  service: process.env.REACT_APP_LOGGER_SERVICE || SERVICE_NAME,
  environment: (process.env.REACT_APP_LOGGER_ENVIRONMENT || 'unknown') as Environments,
  beacon: {
    endpoint: resolveUrlConfigTemplate(process.env.REACT_APP_LOGGER_BEACON_ENDPOINT),
  },
});

/**
 * Do a few things when first creating the logger
 */
const initializeClientLoggerMeta = () => {
  // Set an initial page load correlation id;
  logger.setCorrelationId();

  logger.appendMeta({
    // Set a sessionId for all future events regardless of the current correlationId
    sessionId: logger.getCorrelationId(),
  });

  reportWebVitals(({ id, name, value, delta }: Metric) => {
    logger.info({
      logType: 'WebVitals',
      message: WebVitals[name] || 'Unknown Web Vital name',
      event: name,
      eventId: id,
      eventValue: value,
      eventDelta: delta,
    });
  });

  // Log some device/user information as soon as the app renders.
  logger.info('App initialization', {
    logType: DEFAULT_CLIENT_LOG_TYPE,
    // log userAgent string and userAgentData (not supported by all browsers yet)
    userAgent: navigator.userAgent,
    userAgentData: navigator.userAgentData || 'navigator.userAgentData not supported',

    // Does this device have multiple touch points? i.e., phone or tablet or fancy laptop.
    // Where this is undefined won't be a touch screen either.
    touchScreen: navigator.maxTouchPoints > 1,

    // log viewport size as rough indication of device type
    viewport: getViewportSize(),

    // We want to know what page referred the user to this page/app (backend api can't figure this out)
    referrer: document.referrer,

    // We're curious to see what languages our users prefer based on browser setting.
    // Can have multiple preferred langs, but fall back to `navigator.language` if
    // `navigator.languages` isn't available.
    languages: navigator.languages || [navigator.language],
  });
};

/**
 * clientEvent logs a named client event with the given meta. meta can be overwritten
 * if it collides with the given fields: logType, event.
 *
 * @param name String name to reference the event
 * @param meta Key/value object of additional information
 */
const clientEvent = async (name: string, meta: Record<string, any>) => {
  logger.log({
    level: 'info',
    message: '',
    ...meta,
    logType: DEFAULT_CLIENT_LOG_TYPE,
    event: name,
  });
};

// go ahead and run logger init meta while this module is resolved
initializeClientLoggerMeta();

export { logger, clientEvent, DEFAULT_CLIENT_LOG_TYPE };
