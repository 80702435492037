import { ProductFooter } from '@eftours/cx-shared-components-global-footer';
import { SxProps } from '@mui/material/styles';
import { useProductContext } from '../../services/productContext/ProductProvider';

/**
 * Shared global visual layout. Header, footer, main sections etc
 */
const WrappedProductFooter = ({ sx = [] }: { sx?: SxProps }) => {
  const { businessUnitGroup, locale } = useProductContext().data;

  return <ProductFooter businessUnitGroup={businessUnitGroup} locale={locale} sx={sx} />;
};

export { WrappedProductFooter };
