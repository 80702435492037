import React from 'react';
import { createRoot } from 'react-dom/client';
import { reportWebVitals } from './reportWebVitals';
import { RouteProvider } from './routes/router';
import {
  AppContentProvider,
  ProductContextProvider,
  getProductContext,
} from './services/productContext/ProductProvider';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ProductContextProvider getter={() => getProductContext()}>
      <AppContentProvider>
        <RouteProvider />
      </AppContentProvider>
    </ProductContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
