import React, { Suspense } from 'react';
import { logger } from '../services/logger/logger';
import { ErrorPage } from './Error/Error';
import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary';

type LazyPageComponentProps = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

/**
 * PageWithSuspense is a component to wrap a lazy loaded component with Suspense.
 * It exists to centralize our default fallback for loading JS chunks.
 */
const PageWithSuspense = ({ children, fallback = <></> }: LazyPageComponentProps) => {
  return (
    <Suspense fallback={fallback}>
      <ErrorBoundary renderError={() => <ErrorPage />}>{children}</ErrorBoundary>
    </Suspense>
  );
};

type PageProps = {
  name: string;
  children: React.ReactNode;
};

/**
 * createPageWrapper is a component factory to create a Page component to wrap children in Suspense
 */
const createPageWrapper = () => {
  const Page = ({ name, children }: PageProps) => {
    logger.setCorrelationId();
    logger.appendMeta({ route: name });

    return <PageWithSuspense>{children}</PageWithSuspense>;
  };

  return Page;
};

const Page = createPageWrapper();

export { Page };
