"use strict";
exports.__esModule = true;
exports.RoleType = void 0;
// Same as Individual `typeCode` in Phoenix
var RoleType;
(function (RoleType) {
    RoleType["Participant"] = "PX";
    RoleType["GroupLeader"] = "GL";
    RoleType["LoginCustomer"] = "LC";
    RoleType["Lead"] = "LD";
    RoleType["ContactPerson"] = "CP";
    RoleType["Other"] = "OT";
})(RoleType || (RoleType = {}));
exports.RoleType = RoleType;
