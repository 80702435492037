"use strict";
exports.__esModule = true;
exports.ResourceType = exports.TourStatus = void 0;
/**
 * These are distinct from PaxOrder and GroupTrip StatusCodes
 * These are user facing and do not directly align with any single phx2web field
 * see: https://eftours.atlassian.net/wiki/spaces/CS/pages/2760212909/Designs+Data
 */
var TourStatus;
(function (TourStatus) {
    TourStatus["Upcoming"] = "Upcoming";
    TourStatus["Cancelled"] = "Cancelled";
    TourStatus["Pending"] = "Pending";
    TourStatus["Waitlist"] = "Waitlist";
    TourStatus["Traveled"] = "Traveled";
    TourStatus["NeedsResolution"] = "Needs Resolution";
    TourStatus["None"] = "No Label";
})(TourStatus || (TourStatus = {}));
exports.TourStatus = TourStatus;
var ResourceType;
(function (ResourceType) {
    ResourceType["Link"] = "Link";
    ResourceType["Category"] = "Category";
})(ResourceType || (ResourceType = {}));
exports.ResourceType = ResourceType;
