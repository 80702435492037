/**
 * HACK: getViewportSize is a small utility to get the current viewport size
 * as an enum value (xs, s, m, l, xl, unknown) to log client device type
 *
 * These are just ballpark sizes for user device logging. Values match MUI breakpoint sizes
 * See https://mui.com/material-ui/customization/breakpoints/
 */
const getViewportSize = () => {
  const width = window.visualViewport?.width;

  if (typeof width === 'undefined') {
    return 'unknown';
  }

  if (width < 600) {
    return 'xs';
  }

  if (width < 900) {
    return 's';
  }

  if (width < 1200) {
    return 'm';
  }

  if (width < 1536) {
    return 'l';
  }

  return 'xl';
};

export { getViewportSize };
