"use strict";
exports.__esModule = true;
exports.RewardLineItemStatus = exports.MembershipLevel = void 0;
var MembershipLevel;
(function (MembershipLevel) {
    MembershipLevel["STANDARD"] = "Standard";
    MembershipLevel["BRONZE"] = "Bronze";
    MembershipLevel["SILVER"] = "Silver";
    MembershipLevel["GOLD"] = "Gold";
    MembershipLevel["PLATINUM"] = "Platinum";
    MembershipLevel["DIAMOND"] = "Diamond";
})(MembershipLevel || (MembershipLevel = {}));
exports.MembershipLevel = MembershipLevel;
var RewardLineItemStatus;
(function (RewardLineItemStatus) {
    RewardLineItemStatus["ACTIVE"] = "Active";
    RewardLineItemStatus["PENDING"] = "Pending";
    RewardLineItemStatus["CANCELLED"] = "Cancelled";
    RewardLineItemStatus["CANCELLEDBYSYSTEM"] = "CancelledBySystem";
    RewardLineItemStatus["CANCELLEDBYUSER"] = "CancelledByUser";
    RewardLineItemStatus["NOTAVAILABLE"] = "NotAvailable";
})(RewardLineItemStatus || (RewardLineItemStatus = {}));
exports.RewardLineItemStatus = RewardLineItemStatus;
