import { ReportHandler } from 'web-vitals';

enum WebVitals {
  TTFB = 'Time to First Byte (TTFB)',
  FCP = 'First Contentful Paint (FCP)',
  CLS = 'Cumulative Layout Shift (CLS)',
  FID = 'First Input Delay (FID)',
  LCP = 'Largest Contentful Paint (LCP)',
}

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export { reportWebVitals, WebVitals };
