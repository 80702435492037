import React from 'react';
import { ErrorBoundary as CoreErrorBoundary } from '@eftours/web-logger-typescript-react';
import { logger } from '../../services/logger/logger';
import { Error } from '../Error/Error';

type ErrorBoundaryProps = {
  renderError: () => React.ReactNode;
  children: React.ReactNode;
};

/**
 * ErrorBoundary is a thin wrapper around the error boundary available in
 * @eftours/web-logger-typescript/react. Without additional configuration,
 * it uses the application logger and a default error fallback UI.
 */
const ErrorBoundary = ({ renderError = () => <Error />, children }: ErrorBoundaryProps) => {
  return (
    <CoreErrorBoundary logger={logger} renderError={renderError}>
      {children}
    </CoreErrorBoundary>
  );
};

export { ErrorBoundary };
