"use strict";
exports.__esModule = true;
exports.Environment = void 0;
// Match 'environment' values from dev.tfvars, prod.tfvars, or stage.tfvars
var Environment;
(function (Environment) {
    Environment["Dev"] = "dev";
    Environment["Stage"] = "staging";
    Environment["Prod"] = "prod";
    Environment["Unknown"] = "unknown";
})(Environment || (Environment = {}));
exports.Environment = Environment;
