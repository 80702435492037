"use strict";
exports.__esModule = true;
exports.VoucherStatus = exports.PolicyType = exports.IssuedReasons = void 0;
var IssuedReasons;
(function (IssuedReasons) {
    IssuedReasons["Unknown"] = "Unknown";
    IssuedReasons["Cancellation"] = "Cancellation";
    IssuedReasons["InterruptedTour"] = "Interrupted Tour";
    IssuedReasons["Transfer"] = "Transfer";
    IssuedReasons["Discretionary"] = "Discretionary";
})(IssuedReasons || (IssuedReasons = {}));
exports.IssuedReasons = IssuedReasons;
var PolicyType;
(function (PolicyType) {
    PolicyType["Unknown"] = "Unknown";
    PolicyType["Standard"] = "Standard";
    PolicyType["Covid19"] = "Covid-19";
})(PolicyType || (PolicyType = {}));
exports.PolicyType = PolicyType;
var VoucherStatus;
(function (VoucherStatus) {
    VoucherStatus["Unknown"] = "Unknown";
    VoucherStatus["Created"] = "Created";
    VoucherStatus["Issued"] = "Issued";
    VoucherStatus["Voided"] = "Voided";
    VoucherStatus["Redeemed"] = "Issued (Fully Redeemed)";
})(VoucherStatus || (VoucherStatus = {}));
exports.VoucherStatus = VoucherStatus;
