import { FormattedMessage } from 'react-intl';
import Link from '@mui/material/Link';

type FormattedMessageWithTagsProps = {
  id: string;
  description?: string;
  defaultMessage: string;
  values?: Record<string, any>;
  component?: HTMLAnchorElement;
};

/**
 * FormattedMessageWithTags is a wrapper around FormattedMessage with common tags resolved to JSX.
 *
 * Supported tags:
 *    p,
 *    strong,
 *    em,
 *    u,
 *    ul,
 *    li,
 *    mailto,
 *    tel,
 *
 * Tel and mailto allow using a BU or language differentiated contact to be stored in the same string as the message
 *
 * @param FormattedMessageWithTagsProps
 * @returns <FormattedMessage /> with supported tags replaced by JSX
 */
const FormattedMessageWithTags = ({
  id,
  description = '',
  defaultMessage,
  values = {},
}: FormattedMessageWithTagsProps) => {
  values = {
    p: (inner: React.ReactNode) => <p>{inner}</p>,
    strong: (inner: React.ReactNode) => <strong>{inner}</strong>,
    em: (inner: React.ReactNode) => <em>{inner}</em>,
    u: (inner: React.ReactNode) => <u>{inner}</u>,
    ul: (inner: React.ReactNode) => <ul>{inner}</ul>,
    ol: (inner: React.ReactNode) => <ol>{inner}</ol>,
    li: (inner: React.ReactNode) => <li>{inner}</li>,
    mailto: (inner: string) => <Link href={`mailto:${inner}`}>{inner}</Link>,
    tel: (inner: React.ReactNode) => <Link href={`tel:${inner}`}>{inner}</Link>,
    br: <br />,
    ...values,
  };

  return <FormattedMessage id={id} description={description} defaultMessage={defaultMessage} values={values} />;
};

export { FormattedMessageWithTags };
