import { lazy } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider as BaseRouteProvider } from 'react-router-dom';
import { Page } from '../components/Page';

// Shared
const AppRoot = lazy(() => import('./App/AppRoot'));
const NotFound = lazy(() => import('./NotFound/NotFound'));

// Homepage
const HomepageLayout = lazy(() => import('../layout/HomepageLayout'));
const Homepage = lazy(() => import('./Homepage/Homepage/Homepage'));
const PastTours = lazy(() => import('./Homepage/PastTours/PastTours'));
const Signatures = lazy(() => import('./Homepage/Signatures/SignaturesPage'));
const Vouchers = lazy(() => import('./Homepage/Vouchers/Vouchers'));

// Rewards
const RewardsLayout = lazy(() => import('../layout/RewardsLayout'));
const Rewards = lazy(() => import('./Rewards/Rewards/RewardsPage'));
const Activity = lazy(() => import('./Rewards/Activity/ActivityPage'));
const EarningPoints = lazy(() => import('./Rewards/EarningPoints/EarningPointsPage'));
const FAQs = lazy(() => import('./Rewards/FAQs/FAQsPage'));
const FlightsPortal = lazy(() => import('./Rewards/FlightsPortal/FlightsPortalRedirect'));
const Unenrolled = lazy(() => import('./Rewards/UnenrolledPage'));
const Restricted = lazy(() => import('./Rewards/RestrictedPage'));

const router = createBrowserRouter(
  [
    {
      path: '/not-found',
      element: (
        <Page name='NotFoundPage'>
          <NotFound />
        </Page>
      ),
    },
    {
      path: '/',
      element: (
        <Page name='AppRoot'>
          <AppRoot>
            <Outlet />
          </AppRoot>
        </Page>
      ),
      children: [
        {
          path: '/',
          element: <Navigate to='/my-tours' replace />,
        },
        {
          path: 'my-tours',
          element: (
            <Page name='Homepage'>
              <HomepageLayout />
            </Page>
          ),
          children: [
            {
              index: true,
              element: (
                <Page name='Homepage'>
                  <Homepage />
                </Page>
              ),
            },
            {
              path: 'past',
              element: (
                <Page name='PastTours'>
                  <PastTours />
                </Page>
              ),
            },
            {
              path: 'signatures',
              element: (
                <Page name='Signatures'>
                  <Signatures />
                </Page>
              ),
            },
            {
              path: 'vouchers',
              element: (
                <Page name='Vouchers'>
                  <Vouchers />
                </Page>
              ),
            },
          ],
        },
        {
          path: 'rewards/unenrolled',
          element: (
            <Page name='Unenrolled'>
              <Unenrolled />
            </Page>
          ),
        },
        {
          path: 'rewards/restricted',
          element: (
            <Page name='Restricted'>
              <Restricted />
            </Page>
          ),
        },
        {
          path: 'rewards',
          element: (
            <Page name='Rewards'>
              <RewardsLayout />
            </Page>
          ),
          children: [
            {
              index: true,
              element: (
                <Page name='Rewards'>
                  <Rewards />
                </Page>
              ),
            },
            {
              path: 'activity',
              element: (
                <Page name='Activity'>
                  <Activity />
                </Page>
              ),
            },
            {
              path: 'earning-points',
              element: (
                <Page name='Earning points'>
                  <EarningPoints />
                </Page>
              ),
            },
            {
              path: 'faqs',
              element: (
                <Page name='FAQs'>
                  <FAQs />
                </Page>
              ),
            },
            {
              path: 'flights-portal',
              element: (
                <Page name='FlightsPortal'>
                  <FlightsPortal />
                </Page>
              ),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to='/not-found' replace={true} />,
    },
  ],
  {
    // FIXME: this needs to change to account for global rewards site not being on /my-tours
    basename: process.env.REACT_APP_ROUTER_BASENAME,
  }
);

const RouteProvider = () => <BaseRouteProvider router={router} />;

export { RouteProvider };
